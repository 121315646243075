@tailwind base;
@tailwind components;
@tailwind utilities;

// Override focus ring added by react carousel
.carousel__slide-focus-ring {
    display: none !important;
    outline-width: 0 !important;
}

@import './progressbar.scss';

button, a {
    // Disable ugly focus ring
    @apply focus:outline-0
}

.hero-swirl {
    @apply absolute bottom-[calc((100vh_-_5rem)_*_-0.095)] -right-4 hidden h-[calc(100vh-theme(height.header))] object-contain md:block overflow-hidden;
}

.inline-kiyoh-subheading {
    @apply inline-flex flex-wrap items-center justify-center gap-x-1 text-center text-lg md:flex-wrap md:gap-3;
}

.default-padding {
    @apply px-7.5 2xl:px-0;
}

.section {
    @apply mx-auto w-full;

    &:not(.no-padding) {
        @apply default-padding;

        > .section {
            @apply px-0 2xl:px-0;
        }
    }
}

@layer base {
    :not(nav) > a {
        @apply hover:underline;
    }
}

@layer components {
    @import '../node_modules/pure-react-carousel/dist/react-carousel.es.css';
    @import '../node_modules/keen-slider/keen-slider.min.css';

    .bg-swirl {
        --header-height: theme('spacing.header');
        --content-width: theme('maxWidth.7xl');
        --content-spacing: 5rem;

        --height: calc(100vh  - (2 * var(--header-height)));
        --width: calc(var(--height) * (490.443 / 1008.084));

        --bg-left: min(0px, calc((0.5 * (100vw - var(--content-width))) - var(--content-spacing) - var(--width)));
        --bg-top: calc(1.5 * var(--header-height));

        top: var(--bg-top);
        left: var(--bg-left);
        height: var(--height);

        @apply fixed origin-left overflow-hidden text-orange-500 -z-10;
    }


    // TODO: Deduplicate with above. Is it actually needed on mobile?
    .bg-swirl-mobile {
        --header-height: theme('spacing.header-mobile');
        --content-width: theme('maxWidth.7xl');
        --content-spacing: 5rem;

        --height: calc(100vh  - (2 * var(--header-height)));
        --width: calc(var(--height) * (490.443 / 1008.084));

        --bg-left: min(0px, calc((0.5 * (100vw - var(--content-width))) - var(--content-spacing) - var(--width)));
        --bg-top: calc(1.5 * var(--header-height));

        top: var(--bg-top);
        left: var(--bg-left);
        height: var(--height);

        @apply fixed origin-left overflow-hidden text-orange-500 -z-10;
    }
}

@layer utilities {
    .heading-lg {
        @apply text-2xl xs:text-3xl font-bold lg:text-5xl;
    }

    .heading-center {
        @apply mb-5 mx-5 text-center;
    }

    .savings-calculator {
        @apply z-20 mx-auto my-auto scale-[.8] xs:mx-1 xs:scale-95 sm:mx-5 sm:scale-100 md:max-w-md lg:relative lg:mx-0 lg:ml-0 lg:mr-[calc(100vh*0.25)];
    }

    .kiyoh {
        @apply mx-auto origin-top scale-[.5] object-contain xs:scale-[.7] min-[376px]:scale-100 sm:absolute sm:inset-x-0 sm:bottom-4 sm:my-0 sm:scale-100 md:right-auto md:left-4;
    }

    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
        display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
    }
}
